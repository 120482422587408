export default {
   "en-US": {
      "paginaInicial": {
         "labelSearch": "Search servers",
         "placeholderSearch": "Search...",
         "labelOwner": "Owner",
         "labelGuilds": "Other Guilds"
      },
      "dashboard": {
         "ecommerceStatistics": "Ecommerce Statistics",
         "salesLastDays": "Orders in the last 7 days",
         "noOrdersFound": "No orders found",
         "noSalesFound": "No sales found",
         "value": "Value",
         "bestSellingProductsLastDays": "Best selling products in last 7 days",
         "visitStore": "Visit store",
         "products": "Products",
         "productsDesc": "Products registered in your store",
         "users": "Users",
         "usersDesc": "Users present on your server",
         "openTickets": "Open tickets",
         "openTicketsDesc": "Pending tickets on your server",
         "titlePage": "Expand Your Discord Server",
         "subtitles": {
            "subTitle1": "With powerful features and an intuitive interface, the DECK is designed to provide an unparalleled communication and interaction experience.",
            "subTitle2": "Elevate communication, management, and entertainment to a new level with the DECK, delivering an experience that will exceed all your expectations."
         },
         "welcome": {
            "title": "Welcome & GoodBye",
            "desc": "Customize your welcome and goodbye messages"
         },
         "commands": {
            "title": "General Commands",
            "desc": "see available commands to use on your server"
         },
         "custom": {
            "title": "Custom Commands",
            "desc": "Create and edit your pre-selected command"
         },
         "giveaway": {
            "title": "Giveway",
            "desc": "Participate in Sweepstakes that will occure eventually in guild"
         },
         "pools": {
            "title": "Pools",
            "desc": "Create pools to user can response"
         },
         "ticketSettings": {
            "title": "Ticket Settings",
            "desc": "Configure ticket system in your server"
         },
         "ticketSearch": {
            "title": "Ticket Search",
            "desc": "Search tickets open in your server"
         },
         "wlStg": {
            "title": "Whitelist Settings",
            "desc": "Configure your whitelist system"
         },
         "wlQts": {
            "title": "Whitelist Questions",
            "desc": "Configure questions of your whitelist"
         },
         "wlCfs": {
            "title": "Whitelist Completed Forms",
            "desc": "Search all completed whitelist"
         },
         "landingCards": {
            "welcome": {
               "title": "Welcome & Goodbye",
               "desc": "Personalize the Player Experience on Your Discord Server. Offer a unique experience to players entering and leaving your Discord server with the powerful Welcome and Goodbye tool. With advanced customization features, including highly customizable embeds and images, this is the best option available on the market to make the players' entrance and exit even more special."
            },
            "ticket": {
               "title": "Ticket",
               "desc": "The Support Tool that Simplifies and Organizes Requests Discord Ticket is a powerful support tool designed to provide efficient and organized control of requests on your server. With features like automatic installation and user-friendly simplicity, it takes request management to a new level."
            },
            "wl": {
               "title": "Wl",
               "desc": "The WL Tool that Simplifies the Approval Process Whitelist is an essential tool for servers that want to have strict control over who can access certain resources. With easy installation and configuration, this tool provides an efficient approach by creating multiple-choice questions for users to complete before they are approved."
            }
         },
         "ecommerce": {
            "settings": "Store Settings",
            "settingsDesc": "Configure/customize your ecommerce",
            "products": "Products",
            "productsDesc": "Manage the products of your store",
            "sales": "Sales",
            "salesDesc": "Monitor the sales/orders of your store",
            "monitoring": "Monitoring",
            "monitoringDesc": "Manage the customers and their respective products"
         }
      },
      "navBar": {
         "deckServer": "Deck server",
         "support": "Support",
         "welcome": "Welcome,",
         "exit": "Exit",
         "servers": "My servers",
         "searchServers": "Search servers",
         "subscription": "Subscription",
         "withoutPermission": "Without permission",
         "serverEdit": "Server Manager",
         "dash": "Dashboard",
         "welcomeGood": "Welcome & GoodBye",
         "commands": "General Commands",
         "custom": "Custom Commands",
         "giveaway": "Giveaway",
         "poll": "Polls",
         "ecommerce": {
            "settings": "Settings",
            "products": "Products",
            "sales": "Sales",
            "monitoring": "Monitoring"
         },
         "ticket": {
            "settings": "Settings",
            "search": "Search"
         },
         "whitelist": {
            "settings": "Settings",
            "questions": "Questions",
            "complete": "Completed Forms"
         },
         "serverSettings": "Customize server"
      },
      "server": {
         "modal": {
            "placeholder": {
               "premiumDays": "Not premium",
               "premiumPack": "Select a pack"
            },
            "label": {
               "premiumDays": "Premium days"
            },
            "buttons": {
               "save": "Save",
               "close": "Close"
            }
         },
         "search": {
            "label": "Search servers",
            "placeholder": "Search by id or name"
         }
      },
      "welcomeGoodbye": {
         "joinMessage": {
            "title": "Join Message",
            "desc": "Selected Channel:",
            "error": "Choose a Discord channel for the message."
         },
         "exitMessage": {
            "title": "Exit Message",
            "desc": "Selected Channel:",
            "error": "Choose a Discord channel for the message."
         },
         "privateMessage": {
            "title": "Private Message"
         }
      },
      "generalCommands": {
         
      },
      "customCommands": {
         "buttonAdd": "Add command",
         "editMessage": "Edit Message",
         "addCommand": {
            "title": "Add command",
            "subtitle": "Command"
         }
      },
      "giveaway": {
         "settings": "Settings",
         "participants": "Participants",
         "selected": "Selected Channel",
         "giveAwayDesc": "Start message",
         "winner": {
            "title": "Winner announcement",
            "get": "Get winner",
            "noParticipant": "No participant found"
         },
         "start": {
            "title": "Start",
            "button": "Start giveaway"
         },
         "delete": "Delete giveaway",
         "finish": "Finish giveaway",
         "update": "Update"
      },
      "polls": {
         "selected": "Selected channel",
         "pollDesc": "Start message",
         "alternatives": {
            "title": "Alternatives",
            "label": "Alternative"
         },
         "result": {
            "title": "Result Announcement",
            "sub": "Result",
            "total": "Total votes"
         },
         "start": "Start poll",
         "close": "Close poll",
         "partial": "Partial",
         "finish": "Finish poll",
         "noAlt": "No alternatives found",
         "delete": "Delete poll",
         "update": "Update"
      },
      "ticketSettings": {
         "interactiveButtons": "Interactive buttons",
         "unknown": "Unknown",
         "message": "Message",
         "title": "Title",
         "destMessage": "Destination message",
         "permission": {
            "title": "Select a permission",
            "desc": "This permission is mandatory and allows others to answer tickets.",
            "perm": "Permission"
         },
         "buttons": {
            "settings": "Settings",
            "messages": "Messages",
            "automation": "Automation",
            "save": "Save",
            "back": "Voltar"
         },
         "channelMessage": "Channel",
         "start": "Start",
         "add": "Add",
         "edit": "Edit",
         "remove": "Remove",
         "end": "End",
         "selected": "Selected channel",
         "error": "Choose a Discord channel for the message",
         "removeMessage": "Remove message?",
         "removedMessage": "Message removed!"
      },
      "ecommerceSettings": {
         "channelMessage": "Channel",
         "fillAllFields": "Fill in all fields",
         "nameAlreadyUsed": "Name already used",
         "visitStore": "Visit store",
         "save": "Save",
         "permission": {
            "title": "Select a permission",
            "desc": "This permission is mandatory and allows other people to manage the ecommerce.",
            "perm": "Permission"
         },
         "categories": {
            "title": "Category list",
            "desc": "List of available categories",
            "add": "Add category",
            "addLabel": "Category name",
            "noRes": "No category found"
         },
         "stripe": {
            "title": "Stripe",
            "desc": 'To integrate Stripe with your Ecommerce on AppDeck, you must follow some important steps. First, access the Stripe dashboard. There, locate the "Secret Key" and copy it. Then, in the AppDeck dashboard, go to your Ecommerce settings and paste the secret key. Additionally, to ensure that payments are verified correctly, it is necessary to configure Webhooks in Stripe. In the Stripe control panel, search for "Webhook" and select the "Event destination" option. Click "Add destination" and search for the necessary events. Add the following events: "invoice.created", "invoice.paid", "invoice.payment_action_required", "invoice.payment_failed" and "invoice.payment_succeeded". After adding the events, click "Continue" and select the "Webhook Endpoint" option. On the next screen, you will see the "Endpoint URL" field. Paste the following AppDeck URL: "https://appdeck.online:8446/DeckService/payment/alterarstatusstripe". Finally, click "Create Destination" to finish configuring the Webhooks.',
         },
         "mercadoPago": {
            "title": "Mercado Pago",
            "desc": 'To set up Mercado Pago on your ecommerce site through AppDeck, first go to the official Mercado Pago website. In the side menu, click on "Your Business" and then go to the "Settings" option. Within the settings section, look for and select the "Credentials" option. Then, you will see a QR code, which you can scan using your cell phone. After scanning the code, choose the "Production Credentials" option to access the credentials that will be used in your ecommerce. On the credentials screen, locate the "Access Token" and copy it.',
         },
         "style": {
            "bannerValidation": "Select the previous banner first",
            "name": "Store name",
            "url": "Store URL",
            "logo": "Store logo",
         },
         "buttons": {
            "settings": "Settings",
            "messages": "Messages",
            "payment": "Payment",
            "webhook": "Webhook",
            "purchaseTerms": "Purchase terms"
         },
         "shop": "Shop",
         "thanks": "Thanks",
         "selected": "Selected channel",
         "error": "Choose a Discord channel for the message",
         "savedSettings": "Settings saved!",
         "savedStyle": "Style saved!",
         "savedWebhook": "Webhook saved!",
         "webhook": {
            "addProduct": "Add product",
            "editProduct": "Edit product",
            "deleteProduct": "Delete product",
            "orderPlaced": "Order placed",
            "paymentMade": "Payment made",
            "addCategory": "Add category",
            "deleteCategory": "Delete category",
            "signature": "Signature",
         }
      },
      "ecommerceProducts": {
         "search": {
            "label": "Search",
            "placeholder": "Search by name or category",
            "noRes": "No results found"
         },
         "modal": {
            "showHomePage": "Show on home page",
            "icon": "Icon",
            "name": "Name",
            "price": "Price",
            "description": "Description",
            "permission": "Permission",
            "permissionDuration": "Duration (Days)",
            "urlDownload": "Download URL",
            "category": "Category",
            "resume": "Resume",
            "bannerValidation": "Select the previous banner first",
            "validation": "Fill in all fields",
            "saved": "Product saved!",
            "edit": "Edit",
            "delete": "Delete",
            "deleteDesc": "Delete product?",
            "deleted": "Product deleted!",
            "send": "Send",
            "selectChannel": "Select a channel",
            "invalidChannel": "Invalid channel",
            "sendSuccess": "Product sent!",
         }
      },
      "ecommerceSales": {
         "status": [
            {"key": "Todos", "value": "All"},
            {"key": "Aguardando pagamento", "value": "Waiting for payment"},
            {"key": "Pagamento efetuado", "value": "Payment made"},
            {"key": "Cancelado", "value": "Canceled"},
            {"key": "Finalizado", "value": "Finished"},
         ],
         "initialDate": "Initial date",
         "finalDate": "Final date",
         "paymentMethod": "Payment method",
         "clientDiscord": "Client discord",
         "groupByStatus": "Group by status",
         "unknown": "Unknown",
         "savedStatus": "Status saved!",
         "invalidStatus": "Invalid status",
         "view": "View",
         "date": "Date",
         "lastUpdate": "Last update",
         "value": "Value",
         "products": "Products",
      },
      "ecommerceMonitoring": {
         "status": [
            {"key": "All", "value": "All"},
            {"key": "Active", "value": "Active"},
            {"key": "Inactive", "value": "Inactive"}
         ],
         "typeSearch": "Search type",
         "typeSearchList": [
            {"key": "Expiration date", "value": "Expiration date"},
            {"key": "Purchase date", "value": "Purchase date"},
         ],
         "active": "Active",
         "inactive": "Inactive",
         "purchaseDate": "Purchase date",
         "expirationDate": "Expiration date",
         "unknown": "Unknown",
         "permissions": "Permissions",
         "clientDiscord": "Client discord",
         "initialDate": "Initial date",
         "finalDate": "Final date",
         "orderHistory": "Order history",
         "noRes": "No results found",
         "back": "Back"
      },
      "ticketSearch": {
         "search": {
            "type": "Search type",
            "status": "Status",
            "initial": "Initial date",
            "final": "Final date",
            "statusList": [
               {
                  "key": "all",
                  "value": "All"
               },
               {
                  "key": "new",
                  "value": "New"
               },
               {
                  "key": "inProgress",
                  "value": "In progress"
               },
               {
                  "key": "closed",
                  "value": "Closed"
               }
            ]
         },
         "column": {
            "add": "Add column",
            "edit": "Edit column",
            "name": "Name",
            "color": "Color",
            "icon": "Icon",
         },
         "buttons": {
            "save": "Save",
            "close": "Close",
            "edit": "Edit",
            "delete": "Delete"
         }
      },
      "vipMessage": "You need to be premium to use this function.",
      "whitelistSettings": {
         "testConnection": "You need to test the connection to save the settings",
         "savedSettings": "Settings saved!",
         "settings": "Settings",
         "save": "Save",
         "messages": "Messages",
         "correct": {
            "title": "Correct answers",
            "desc": "This indicates the minimum amount of correct answers to be approved."
         },
         "permission": {
            "title": "Permission",
            "desc": "This permission is required and allows others to edit questions and view forms."
         },
         "automatic": {
            "title": "Automatic validation",
            "desc": "This permission is optional and defines whether the Whitelist approval will be automatic or manual",
            "manual": "Manual",
            "auto": "Automatic"
         },
         "sql": {
            "title": "Sql",
            "desc": "This indicates the script that will run on your database in case of player approval."
         },
         "dataBase": {
            "title": "Database",
            "desc": "It is necessary to add a database with external access granted in order to approve the player automatically",
            "host": "Hostname",
            "name": "Database name",
            "port": "Port",
            "username": "Username",
            "password": "Password",
            "success": "Connection established!"
         },
         "button": "Test connection",
         "alter": "Alter settings",
         "correctcorrectAnswers": "Correct answers",
         "correctDesc": "This indicates the minimum amount of correct answers to be approved.",
         "permissionWl": "Permission",
         "permisionDesc": "This permission is required and allows others to edit questions and view forms.",
         "roleWl": "Discord role",
         "roleDesc": "Role that user will gain if approved.",
         "validation": "Automatic validation",
         "validationDesc": "This permission is optional and defines whether the Whitelist approval will be automatic or manual",
         "initWl": "Label Whitelist",
         "initWlDesc": "Message label indicates the steam, id or other."
      },
      "whitelistQuestions": {
         "button": {
            "add": "Add"
         },
         "noRes": "No question found"
      },
      "whitelistCompletedForms": {
         "search": {
            "type": "Search type",
            "playerId": "Identity player",
            "initial": "Initial date",
            "final": "Final date",
            "noRes": "No results found",
            "formInfo": "Form Info",
            "types": [
               {
                  "key": "discord",
                  "value": "Discord"
               },
               {
                  "key": "playerIdentity",
                  "value": "Player identity"
               }
            ]
         },
         "answers": {
            "correct": "Correct Answers",
            "playerId": "Identity player",
            "approve": "Approve user",
            "answers": "Answers",
            "close": "Close"
         }
      },
      "premium": {
         "seePlans": "See plans",
         "config": {
            "title": "Monthly subscription",
            "why": "Why choose premium?",
            "free": "Free",
            "basic": "Basic",
            "standard": "Standard",
            "ultimate": "Ultimate",
            "textGrids": {
               "welcome": "Welcome messages",
               "customWl": "Custom whitelist",
               "customImg": "Custom images",
               "customCmd": "Custom commands",
               "commands": "General commands",
               "customPoll": "Custom polls",
               "monthly": "Raffles",
               "ticket": "Ticket system",
               "privateMessage": "Private messages",
               "shop": "Exclusive ecommerce",
               "shopWebhook": "Ecommerce - Webhook",
               "shopBanners": "Ecommerce - 3 banners",
               "shopCustom": "Ecommerce - Change name and logo",
               "shopBadge": "Ecommerce - Verified badge",
               "shopCommands": "Ecommerce - Commands on discord"
            }
         },
         "booster": {
            "currentPlan": "Current plan",
            "paymentMethod": "Payment method",
            "active": "Current",
            "subscribe": "Subscribe",
            "buy": "Buy",
            "process": "Process...",
            "update": "Updated",
            "charger": "Next charger",
            "expiration": "Expiration date",
            "unknown": "unknown",
            "cancel": "Cancel",
            "cancelAfterExpires": "Expires on "
         },
         "donate": {
            "title": "Do you like our application?",
            "desc": "Welcome to our donation page! By choosing to support our cause, you are making a direct impact on our mission and enabling us to continue the important work we do. Your contribution, no matter the amount, helps us bring positive change to our development",
            "btn": "donate"
         }
      },
      "serverSettings": {
         "updateLimit": "Each ticket is updated a maximum of 2 times every 10 minutes.",
         "necessaryOwner": "Necessary to be owner",
         "chooseChannel": "Choose a Discord channel for the message",
         "messageSent": "Message sent!",
         "status": {
            "toggle": "Add",
            "add": "Add status",
            "addDesc": "Add custom status",
            "save": "Save",
            "prefix": "Prefix",
            "desc": "Description",
            "invalid": "Nome inválido!"
         },
         "buttons": {
            "edit": "Edit",
            "send": "Send",
            "test": "Test",
            "editBtn": "  Edit",
            "deleteBtn": "  Delete",
            "back": "Back",
         },
         "editMessage": {
            "title": "Alter Preview",
            "buttons": {
               "save": "Save",
               "close": "Close"
            }
         },
         "selectChannel": {
            "title": "Alter channel",
            "sub": "Select a Channel",
            "buttons": {
               "close": "Close"
            }
         },
         "alterSettings": {
            "title": "Alter Settings",
            "permission": {
               "title": "Select a Permission",
               "sub": "This permission is mandatory and allow others to answer tickets.",
               "button": "Close"
            }
         },
         "addQuestion": {
            "title": "Add question",
            "edit": "Edit question",
            "questionInfo": {
               "title": "Question info",
               "desc": "Description",
               "answer": "N° answer"
            },
            "alternatives": {
               "title": "Alternatives",
               "button": {
                  "add": "Add"
               }
            },
            "buttons": {
               "save": "Save",
               "close": "Close"
            }
         },
         "swal": {
            "message": "Toggled message!",
            "expired": "Session expired!",
            "delCmd": "Delete command",
            "commandDeleted": "Command deleted",
            "saved": "Saved channel",
            "invalidChannel": "Invalid channel",
            "savedPreview": "Saved preview",
            "order": "Order processed",
            "invName": "Invalid name",
            "invColor": "Invalid Color",
            "limit": "Reached limit",
            "attachsLimit": "'Maximum attachments exceeded (10).'",
            "sizeExcedd": "Size exceeded",
            "fileSizeExcedd": "File exceeds 25MB",
            "invDesc": "Invalid description",
            "inviteCreate": "Invite generated",
            "infoSaved": "Info saved",
            "btnCancel": "Cancel",
            "btnConfirm": "Confirm",
            "question": {
               "delQuestion": "Delete Question",
               "delSucess": "Question Deleted",
               "addAlternative": "Add alternative",
               "descAlternative": "Type below",
               "invalidAlt": "Invalid alternative",
               "saved": "Saved question!"
            },
            "giveAway": {
               "start": "Start giveaway",
               "started": "Giveaway Started",
               "delete": "Delete giveaway",
               "deleted": "Giveaway deleted",
               "winner": "Select winner",
               "selectedWinner": "winner",
               "ended": "Giveaway ended"
            },
            "polls": {
               "start": "Start poll",
               "started": "Poll is started",
               "delete": "Delete poll",
               "deleted": "Deleted poll",
               "close": "Close poll",
               "closed": "Ended Pool"
            },
            "premium": {
               "payment": {
                  "title": "Processing payment",
                  "desc": "Your payment is being processed!"
               },
               "subs": {
                  "cancel": {
                     "title": "Cancel subscription",
                     "ended": "Your subscription will end on: "
                  }
               }
            },
            "ticket": {
               "questDeleted": "Are you sure you want to delete this column?",
               "saved": "Column saved!",
               "create": "Create column",
               "delCol": "Delete column",
               "createdCol": "Column created!",
               "deletedCol": "Column deleted!",
               "deletedTicket": "Ticket deleted!",
               "closedTicket": "Ticket Closed"
            }
         },
         "login": {
            "title": "Robot for discord",
            "discord": "Join with discord"
         },
         "selectCategory": {
            "category": "Select a category",
            "desc": "All tickets will be directed to this category"
         },
         "statusArray": {
            "title": "Status list",
            "desc": "List of available status for the ticket"
         }
      },
      "addServer": {
         "titlePageAdd": "ADD BOT",
         "subTitlePageAdd": "Bot installation, click the button below 'ADD BOT!', to install on your server",
         "btnTitle": "ADD BOT!",
         "attentionNote": {
            "title": "Attention!",
            "subTitle": "Click the button above 'ADD BOT!' to install."
         },
         "denyNote": {
            "btnNote": "RETURN",
            "title": "Attention Note",
            "subTitle": "You must own or administer the server."
         }
      },
      "previewMessage": {
         "preview": {
            "title": "Preview",
            "label": "Your message here"
         },
         "message": "Message",
         "custom": {
            "title": "Customize Message",
            "label": "Your message here"
         },
         "buttons": {
            "image": "image",
            "embed": "Embed"
         },
         "image": {
            "title": "Message by image",
            "menu": {
               "custom": {
                  "titleMenu": "Customize",
                  "titleText": "Title",
                  "subtitleText": "Subtitle",
                  "textColor": "Text Color",
                  "select": "Select Font",
                  "border": "Border size",
                  "font": "Font size"
               },
               "select": "Select Image",
               "upload": "Upload image"
            }
         },
         "uploadImage": {
            "title": "Send Image",
            "up": "Upload",
            "buttons": {
               "save": "Save",
               "cancel": "Cancel"
            }
         },
         "embedMessage": {
            "title": "Message by embed",
            "color": "Color stripe",
            "author": "Author",
            "titleEmbed": "Title",
            "message": "Message",
            "fields": {
               "title": "Fields",
               "btn": "Add field",
               "field": {
                  "title": "Field",
                  "subTitle": "Field title",
                  "value": "Field value"
               },
               "remove": "Remove field"
            },
            "footer": "Footer",
            "image": "Image message"
         }
      },
      "congratsPage": {
         "title": "Thank you for installing our Discord bot",
         "texts": [
            "Hello! We would like to express our sincere gratitude for installing our Discord bot on your server. Your trust in our technology and team is extremely valuable to us.",
            "With our Discord bot, we hope to provide a unique and enjoyable experience for your users. Our goal is to facilitate communication and information sharing on your server, helping you to build a strong and engaged community.",
            "We constantly strive to improve our bot and bring new features to make your experience even better. We are excited for the future and can't wait to share the updates we are preparing.",
            "Once again, thank you very much for choosing our Discord bot. We hope to continue providing high-quality technological solutions and helping your community grow and prosper."
         ],
         "button": "Go!"
      },
      "donateConfig": {
         "configPage": {
            "server": "Server info",
            "serverBenefits": "This information will appear in the ranking tab on our website.",
            "description": "Description",
            "tags": "Tags",
            "url": "URL invite",
            "generate": "Generate",
            "urlBanner": "URL banner",
            "save": "Save"
         }
      }
   },
   "pt-BR": {
      "paginaInicial": {
         "labelSearch": "Buscar servidores",
         "placeholderSearch": "Pesquisar...",
         "labelOwner": "Dono",
         "labelGuilds": "Outros Servidores"
      },
      "dashboard": {
         "ecommerceStatistics": "Estatísticas do ecommerce",
         "salesLastDays": "Pedidos nos últimos 7 dias",
         "noOrdersFound": "Nenhum pedido encontrado",
         "noSalesFound": "Nenhuma venda encontrada",
         "value": "Valor",
         "bestSellingProductsLastDays": "Produtos mais vendidos nos últimos 7 dias",
         "visitStore": "Visitar loja",
         "products": "Produtos",
         "productsDesc": "Produtos cadastrados em sua loja",
         "users": "Usuários",
         "usersDesc": "Usuários presentes em seu servidor",
         "openTickets": "Tickets abertos",
         "openTicketsDesc": "Tickets pendentes em seu servidor",
         "titlePage": "Expanda seu Servidor do Discord",
         "subtitles": {
            "subTitle1": "Com recursos poderosos e uma interface intuitiva, o DECK foi projetado para proporcionar uma experiência de comunicação e interação incomparável.",
            "subTitle2": "Eleve a comunicação, gestão e entretenimento a um novo patamar com o DECK, oferecendo uma experiência que superará todas as suas expectativas."
         },
         "welcome": {
            "title": "Bem-vindo e Adeus",
            "desc": "Personalize suas mensagens de boas-vindas e despedida"
         },
         "commands": {
            "title": "Comandos Gerais",
            "desc": "Veja os comandos disponíveis para usar em seu servidor"
         },
         "custom": {
            "title": "Comandos Personalizados",
            "desc": "Crie e edite seus comandos pré-selecionados"
         },
         "giveaway": {
            "title": "Sorteio",
            "desc": "Participe de sorteios que ocorrerão eventualmente no servidor"
         },
         "pools": {
            "title": "Enquetes",
            "desc": "Crie enquetes para os usuários responderem"
         },
         "ticketSettings": {
            "title": "Configurações de Tickets",
            "desc": "Configure o sistema de tickets em seu servidor"
         },
         "ticketSearch": {
            "title": "Pesquisa de Tickets",
            "desc": "Pesquise os tickets abertos em seu servidor"
         },
         "wlStg": {
            "title": "Configurações de Whitelist",
            "desc": "Configure seu sistema de Whitelist"
         },
         "wlQts": {
            "title": "Perguntas da Whitelist",
            "desc": "Configure as perguntas da sua Whitelist"
         },
         "wlCfs": {
            "title": "Whitelist Completas",
            "desc": "Pesquise todos os formulários de Whitelist preenchidos"
         },
         "landingCards": {
            "welcome": {
               "title": "Bem-vindo e Adeus",
               "desc": "Personalize a Experiência do Jogador em seu Servidor do Discord. Ofereça uma experiência única aos jogadores que entram e saem do seu servidor do Discord com a poderosa ferramenta de Boas-vindas e Despedida. Com recursos avançados de personalização, incluindo mensagens embutidas e imagens altamente personalizáveis, esta é a melhor opção disponível no mercado para tornar a entrada e saída dos jogadores ainda mais especiais."
            },
            "ticket": {
               "title": "Ticket",
               "desc": "A Ferramenta de Suporte que Simplifica e Organiza as Solicitações. O Discord Ticket é uma poderosa ferramenta de suporte projetada para fornecer controle eficiente e organizado das solicitações em seu servidor. Com recursos como instalação automática e simplicidade amigável ao usuário, ele leva a gestão de solicitações a um novo nível."
            },
            "wl": {
               "title": "Whitelist",
               "desc": "A Ferramenta de WL que Simplifica o Processo de Aprovação. A Whitelist é uma ferramenta essencial para servidores que desejam ter um controle rigoroso sobre quem pode acessar determinados recursos. Com instalação e configuração fáceis, esta ferramenta oferece uma abordagem eficiente criando perguntas de múltipla escolha para os usuários completarem antes de serem aprovados."
            }
         },
         "ecommerce": {
            "settings": "Configurações da loja",
            "settingsDesc": "Configure/personalize seu ecommerce",
            "products": "Produtos",
            "productsDesc": "Gerencie os produtos da sua loja",
            "sales": "Vendas",
            "salesDesc": "Monitore as vendas/pedidos da sua loja",
            "monitoring": "Monitoramento",
            "monitoringDesc": "Gerencie os clientes e seus respectivos produtos"
         }
      },
      "server": {
         "modal": {
            "placeholder": {
               "premiumDays": "Não é premium",
               "premiumPack": "Selecione um pacote"
            },
            "label": {
               "premiumDays": "Dias premium"
            },
            "buttons": {
               "save": "Salvar",
               "close": "Fechar"
            }
         },
         "search": {
            "label": "Buscar servidores",
            "placeholder": "Pesquise por ID ou nome"
         }
      },
      "welcomeGoodbye": {
         "joinMessage": {
            "title": "Mensagem de Entrada",
            "desc": "Canal Selecionado:",
            "error": "Escolha um canal do Discord para a mensagem."
         },
         "exitMessage": {
            "title": "Mensagem de Saída",
            "desc": "Canal Selecionado:",
            "error": "Escolha um canal do Discord para a mensagem."
         },
         "privateMessage": {
            "title": "Mensagem Privada"
         }
      },
      "generalCommands": {
         
      },
      "customCommands": {
         "buttonAdd": "Adiconar",
         "editMessage": "Editar Mensagem",
         "addCommand": {
            "title": "Adicionar comando",
            "subtitle": "Commando"
         }
      },
      "giveaway": {
         "participants": "Participantes",
         "settings": "Configurações",
         "selected": "Canal Selecionado",
         "giveAwayDesc": "Mensagem de Início",
         "winner": {
            "title": "Anúncio do Vencedor",
            "get": "Obter vencedor",
            "noParticipant": "Nenhum participante encontrado"
         },
         "start": {
            "title": "Iniciar",
            "button": "Iniciar sorteio"
         },
         "delete": "Excluir sorteio",
         "finish": "Finalizar sorteio",
         "update": "Atualizar"
      },
      "polls": {
         "selected": "Canal Selecionado",
         "pollDesc": "Mensagem de Início",
         "alternatives": {
            "title": "Alternativas",
            "label": "Alternativa"
         },
         "result": {
            "title": "Anúncio de Resultado",
            "sub": "Resultado",
            "total": "Total de votos"
         },
         "start": "Iniciar enquete",
         "close": "Encerrar enquete",
         "partial": "Parcial",
         "finish": "Finalizar enquete",
         "noAlt": "Nenhuma alternativa encontrada",
         "delete": "Apagar enquete",
         "update": "Atualizar"
      },
      "ticketSettings": {
         "interactiveButtons": "Botões interativos",
         "unknown": "Desconhecido",
         "message": "Mensagem",
         "title": "Título",
         "destMessage": "Mensagem destino",
         "permission": {
            "title": "Selecionar uma permissão",
            "desc": "Esta permissão é obrigatória e permite que outros respondam aos tickets.",
            "perm": "Permissão"
         },
         "buttons": {
            "settings": "Configurações",
            "messages": "Mensagens",
            "automation": "Automação",
            "save": "Salvar",
            "back": "Voltar"
         },
         "channelMessage": "Canal",
         "start": "Início",
         "add": "Adicionar",
         "edit": "Editar",
         "remove": "Remover",
         "end": "Fim",
         "selected": "Canal Selecionado",
         "error": "Escolha um canal do Discord para a mensagem",
         "removeMessage": "Remover mensagem?",
         "removedMessage": "Mensagem removida!"
      },
      "ecommerceSettings": {
         "channelMessage": "Canal",
         "fillAllFields": "Preencha todos os campos",
         "nameAlreadyUsed": "Nome já utilizado",
         "visitStore": "Visitar loja",
         "save": "Salvar",
         "permission": {
            "title": "Selecionar uma permissão",
            "desc": "Esta permissão é obrigatória e permite que outras pessoas gerenciem o ecommerce.",
            "perm": "Permissão"
         },
         "categories": {
            "title": "Lista de categorias",
            "desc": "Lista de categorias disponíveis",
            "add": "Adicionar categoria",
            "addLabel": "Nome da categoria",
            "noRes": "Nenhuma categoria encontrada"
         },
         "stripe": {
            "title": "Stripe",
            "desc": 'Para integrar o Stripe ao seu Ecommerce no AppDeck você deve seguir alguns passos importantes. Primeiramente, acesse a dashboard do Stripe. Nela, localize a "Chave Secreta" e copie essa chave. Em seguida, no painel do AppDeck, vá até as configurações do seu Ecommerce e cole a chave secreta. Além disso, para garantir que os pagamentos sejam verificados corretamente, é necessário configurar as Webhooks no Stripe. No painel de controle do Stripe, pesquise por "Webhook" e selecione a opção "Destino de eventos". Clique em "Adicionar destino" e procure pelos eventos necessários. Adicione os seguintes eventos: "invoice.created", "invoice.paid", "invoice.payment_action_required", "invoice.payment_failed" e "invoice.payment_succeeded". Depois de adicionar os eventos, clique em "Continuar" e selecione a opção "Endpoint de Webhook". Na tela seguinte, você verá o campo "URL do endpoint". Cole a seguinte URL do AppDeck: "https://appdeck.online:8446/DeckService/payment/alterarstatusstripe". Por fim, clique em "Criar destino" para finalizar a configuração das Webhooks.',
         },
         "mercadoPago": {
            "title": "Mercado Pago",
            "desc": 'Para configurar o Mercado Pago em seu ecommerce através do AppDeck, primeiro acesse o site oficial do Mercado Pago. No menu lateral, clique em "Seu Negócio" e depois vá até a opção "Configurações". Dentro da seção de configurações, procure e selecione a opção "Credenciais". Em seguida, você verá um código QR, que pode ser escaneado utilizando o seu celular. Após escanear o código, escolha a opção "Credenciais de produção" para acessar as credenciais que serão usadas em seu ecommerce. Na tela de credenciais, localize o "Access Token" e copie-o.',
         },
         "style": {
            "bannerValidation": "Selecione primeiro o banner anterior",
            "name": "Nome da loja",
            "url": "URL da loja",
            "logo": "Logo da loja",
         },
         "buttons": {
            "settings": "Configurações",
            "messages": "Mensagens",
            "payment": "Pagamento",
            "webhook": "Webhook",
            "purchaseTerms": "Termos de compras"
         },
         "shop": "Loja",
         "thanks": "Agradecimento",
         "selected": "Canal Selecionado",
         "error": "Escolha um canal do Discord para a mensagem",
         "savedSettings": "Configurações salvas!",
         "savedStyle": "Estilo salvo!",
         "savedWebhook": "Webhook salvo!",
         "webhook": {
            "addProduct": "Adicionar produto",
            "editProduct": "Editar produto",
            "deleteProduct": "Deletar produto",
            "orderPlaced": "Pedido realizado",
            "paymentMade": "Pagamento realizado",
            "addCategory": "Adicionar categoria",
            "deleteCategory": "Deletar categoria",
            "signature": "Assinatura"
         }
      },
      "ecommerceProducts": {
         "search": {
            "label": "Buscar",
            "placeholder": "Buscar por nome ou categoria",
            "noRes": "Nenhum resultado encontrado"
         },
         "modal": {
            "showHomePage": "Mostrar na página inicial",
            "icon": "Ícone",
            "name": "Nome",
            "price": "Preço",
            "description": "Descrição",
            "permission": "Permissão",
            "permissionDuration": "Duração (Dias)",
            "urlDownload": "URL de Download",
            "category": "Categoria",
            "resume": "Resumo",
            "bannerValidation": "Selecione primeiro o banner anterior",
            "validation": "Preencha todos os campos",
            "saved": "Produto salvo!",
            "edit": "Editar",
            "delete": "Deletar",
            "deleteDesc": "Deletar produto?",
            "deleted": "Produto deletado!",
            "send": "Enviar",
            "selectChannel": "Selecione um canal",
            "invalidChannel": "Canal inválido",
            "sendSuccess": "Produto enviado!",
         }
      },
      "ecommerceSales": {
         "status": [
            {"key": "Todos", "value": "Todos"},
            {"key": "Aguardando pagamento", "value": "Aguardando pagamento"},
            {"key": "Pagamento efetuado", "value": "Pagamento efetuado"},
            {"key": "Cancelado", "value": "Cancelado"},
            {"key": "Finalizado", "value": "Finalizado"},
         ],
         "initialDate": "Data inicial",
         "finalDate": "Data final",
         "paymentMethod": "Método de pagamento",
         "clientDiscord": "Discord do cliente",
         "groupByStatus": "Agrupar por status",
         "unknown": "Desconhecido",
         "savedStatus": "Status salvo!",
         "invalidStatus": "Status inválido",
         "view": "Visualizar",
         "date": "Data",
         "lastUpdate": "Última atualização",
         "value": "Valor",
         "products": "Produtos",
      },
      "ecommerceMonitoring": {
         "status": [
            {"key": "All", "value": "Todos"},
            {"key": "Active", "value": "Ativo"},
            {"key": "Inactive", "value": "Inativo"}
         ],
         "typeSearch": "Tipo de busca",
         "typeSearchList": [
            {"key": "Expiration date", "value": "Data expiração"},
            {"key": "Purchase date", "value": "Data compra"},
         ],
         "active": "Ativo",
         "inactive": "Inativo",
         "purchaseDate": "Data de compra",
         "expirationDate": "Data de expiração",
         "unknown": "Desconhecido",
         "permissions": "Permissões",
         "clientDiscord": "Discord do cliente",
         "initialDate": "Data inicial",
         "finalDate": "Data final",
         "orderHistory": "Histórico de pedidos",
         "noRes": "Nenhum resultado encontrado",
         "back": "Voltar"
      },
      "ticketSearch": {
         "search": {
            "type": "Tipo de Busca",
            "status": "Status",
            "initial": "Data Inicial",
            "final": "Data Final",
            "statusList": [
               {
                  "key": "all",
                  "value": "Tudo"
               },
               {
                  "key": "new",
                  "value": "Novo"
               },
               {
                  "key": "inProgress",
                  "value": "Em progresso"
               },
               {
                  "key": "closed",
                  "value": "Fechado"
               }
            ]
         },
         "column": {
            "add": "Adicionar coluna",
            "edit": "Editar coluna",
            "name": "Nome",
            "color": "Cor",
            "icon": "Ícone",
         },
         "buttons": {
            "save": "Salvar",
            "close": "Fechar",
            "edit": "Editar",
            "delete": "Excluir"
         }
      },
      "vipMessage": "Você precisa ser premium para usar esta função.",
      "whitelistSettings": {
         "testConnection": "É necessário testar a conexão para salvar as configurações",
         "savedSettings": "Configurações salvas!",
         "settings": "Configurações",
         "save": "Salvar",
         "messages": "Mensagens",
         "correct": {
            "title": "Respostas corretas",
            "desc": "Isso indica a quantidade mínima de respostas corretas para ser aprovado."
         },
         "permission": {
            "title": "Permissão",
            "desc": "Esta permissão é obrigatória e permite que outros editem perguntas e vejam formulários."
         },
         "automatic": {
            "title": "Validação automática",
            "desc": "Esta permissão é opcional e define se a aprovação na Whitelist será automática ou manual",
            "manual": "Manual",
            "auto": "Automática"
         },
         "sql": {
            "title": "SQL",
            "desc": "Isso indica o script que será executado no seu banco de dados em caso de aprovação do jogador."
         },
         "dataBase": {
            "title": "Banco de dados",
            "desc": "É necessário adicionar um banco de dados com acesso externo concedido para aprovar o jogador automaticamente",
            "host": "Nome do Host",
            "name": "Nome do Banco de Dados",
            "port": "Porta",
            "username": "Nome de Usuário",
            "password": "Senha",
            "success": "Conexão estabelecida!"
         },
         "button": "Testar conexão",
         "alter": "Alterar configurações",
         "correctAnswers": "Respostas corretas",
         "correctDesc": "Isso indica a quantidade mínima de respostas corretas para ser aprovado.",
         "permissionWl": "Permissão",
         "permisionDesc": "Esta permissão é obrigatória e permite que outros editem perguntas e vejam formulários.",
         "roleWl": "Cargo no Discord",
         "roleDesc": "Cargo que usuário irá ganhar caso seja aprovado.",
         "validation": "Validação automática",
         "validationDesc": "Esta permissão é opcional e define se a aprovação na Whitelist será automática ou manual",
         "initWl": "Label da Whitelist",
         "initWlDesc": "A mensagem de label indica o Steam, ID ou outro."
      },
      "whitelistQuestions": {
         "button": {
            "add": "Adicionar"
         },
         "noRes": "Nenhuma pergunta encontrada"
      },
      "whitelistCompletedForms": {
         "search": {
            "type": "Tipo de busca",
            "playerId": "Identificação do jogador",
            "initial": "Data inicial",
            "final": "Data final",
            "noRes": "Nenhum resultado encontrado",
            "formInfo": "Informações do formulário",
            "types": [
               {
                  "key": "discord",
                  "value": "Discord"
               },
               {
                  "key": "playerIdentity",
                  "value": "Identificação do jogador"
               }
            ]
         },
         "answers": {
            "correct": "Respostas corretas",
            "playerId": "Identificação do jogador",
            "approve": "Aprovar usuário",
            "answers": "Respostas",
            "close": "Fechar"
         }
      },
      "premium": {
         "seePlans": "Ver planos",
         "config": {
            "title": "Assinatura Mensal",
            "why": "Por que o Premium?",
            "free": "Free",
            "basic": "Basic",
            "standard": "Standard",
            "ultimate": "Ultimate",
            "textGrids": {
               "welcome": "Mensagens de boas-vindas",
               "customWl": "Whitelist personalizada",
               "customImg": "Imagens personalizadas",
               "customCmd": "Comandos personalizados",
               "commands": "Comandos gerais",
               "customPoll": "Enquetes personalizadas",
               "monthly": "Sorteios",
               "ticket": "Sistema de tickets",
               "privateMessage": "Mensagens privadas",
               "shop": "Ecommerce exclusivo",
               "shopWebhook": "Ecommerce - Webhook",
               "shopBanners": "Ecommerce - 3 banners",
               "shopCustom": "Ecommerce - Alterar nome e logo",
               "shopBadge": "Ecommerce - Selo de verificado",
               "shopCommands": "Ecommerce - Comandos no discord"
            }
         },
         "booster": {
            "currentPlan": "Plano atual",
            "paymentMethod": "Forma de pagamento",
            "active": "Atual",
            "subscribe": "Assinar",
            "buy": "Comprar",
            "process": "Processando...",
            "update": "Atualizado",
            "charger": "Próxima cobrança",
            "expiration": "Data de expiração",
            "unknown": "desconhecido",
            "cancel": "Cancelar",
            "cancelAfterExpires": "Expira em "
         },
         "donate": {
            "title": "Você gosta do nosso aplicativo?",
            "desc": "Bem-vindo à nossa página de doações! Ao escolher apoiar nossa causa, você está fazendo um impacto direto em nossa missão e nos permitindo continuar o importante trabalho que realizamos. Sua contribuição, não importa o valor, nos ajuda a trazer mudanças positivas para o nosso desenvolvimento.",
            "btn": "Doar"
         }
      },
      "serverSettings": {
         "updateLimit": "Cada ticket é atualizado ao máximo 2 vezes a cada 10 minutos.",
         "necessaryOwner": "Necessário ser dono",
         "chooseChannel": "Escolha um canal do Discord para a mensagem",
         "messageSent": "Mensagem enviada!",
         "status": {
            "toggle": "Adicionar",
            "add": "Adicionar status",
            "addDesc": "Adicionar status customizado",
            "save": "Salvar",
            "prefix": "Prefixo",
            "desc": "Descrição",
            "invalid": "Nome inválido!"
         },
         "buttons": {
            "edit": "Editar",
            "send": "Enviar",
            "test": "Testar",
            "editBtn": "  Editar",
            "deleteBtn": "  Deletar",
            "back": "Voltar"
         },
         "editMessage": {
            "title": "Alterar Pré-visualização",
            "buttons": {
               "save": "Salvar",
               "close": "Fechar"
            }
         },
         "selectChannel": {
            "title": "Alterar Canal",
            "sub": "Selecione um Canal",
            "buttons": {
               "close": "Fechar"
            }
         },
         "alterSettings": {
            "title": "Alterar Configurações",
            "permission": {
               "title": "Selecionar uma Permissão",
               "sub": "Esta permissão é obrigatória e permite que outros respondam aos tickets.",
               "button": "Fechar"
            }
         },
         "addQuestion": {
            "title": "Adicionar Pergunta",
            "edit": "Editar Pergunta",
            "questionInfo": {
               "title": "Informações da Pergunta",
               "desc": "Descrição",
               "answer": "N° de resposta"
            },
            "alternatives": {
               "title": "Alternativas",
               "button": {
                  "add": "Adicionar"
               }
            },
            "buttons": {
               "save": "Salvar",
               "close": "Fechar"
            }
         },
         "swal": {
            "message": "Mensagem alterada!",
            "expired": "Sessão expirada!",
            "delCmd": "Deletar comando",
            "commandDeleted": "Comando deletado",
            "saved": "Canal salvo",
            "invalidChannel": "Canal inválido",
            "savedPreview": "Pré-visualização salva",
            "order": "Ordem processada",
            "invName": "Nome inválido",
            "invColor": "Cor inválida",
            "limit": "Limite alcançado",
            "attachsLimit": "Limite máximo de anexos excedido (10).",
            "sizeExcedd": "Tamanho excedido",
            "fileSizeExcedd": "Arquivo excede 25MB",
            "invDesc": "Descrição inválida",
            "inviteCreate": "Convite criado",
            "infoSaved": "Salvo",
            "btnCancel": "Cancelar",
            "btnConfirm": "Confirmar",
            "question": {
               "delQuestion": "Excluir Pergunta",
               "delSucess": "Pergunta Excluída",
               "addAlternative": "Adicionar alternativa",
               "descAlternative": "Digite abaixo",
               "invalidAlt": "Alternativa inválida",
               "saved": "Pergunta salva!"
            },
            "giveAway": {
               "start": "Iniciar sorteio",
               "started": "Sorteio Iniciado",
               "delete": "Excluir sorteio",
               "deleted": "Sorteio excluído",
               "winner": "Selecionar vencedor",
               "selectedWinner": "Vencedor: ",
               "ended": "Sorteio encerrado"
            },
            "polls": {
               "start": "Iniciar enquete",
               "started": "Enquete iniciada",
               "delete": "Excluir enquete",
               "deleted": "Enquete excluída",
               "close": "Encerrar enquete",
               "closed": "Enquete encerrada"
            },
            "premium": {
               "payment": {
                  "title": "Processando pagamento",
                  "desc": "Seu pagamento está sendo processado!"
               },
               "subs": {
                  "cancel": {
                     "title": "Cancelar assinatura",
                     "ended": "Sua assinatura será encerrada em: "
                  }
               }
            },
            "ticket": {
               "questDeleted": "Dejesa deletar essa coluna?",
               "saved": "Coluna salva!",
               "create": "Criar coluna",
               "delCol": "Excluir coluna",
               "createdCol": "Coluna criada!",
               "deletedCol": "Coluna excluída!",
               "deletedTicket": "Ticket excluído!",
               "closedTicket": "Ticket Fechado"
            }
         },
         "login": {
            "title": "Bot para discord",
            "discord": "Entrar via discord"
         },
         "selectCategory": {
            "category": "Selecione uma categoria",
            "desc": "Todos os tickets serão direcionados para esta categoria"
         },
         "statusArray": {
            "title": "Lista de status",
            "desc": "Lista de status disponíveis para o ticket"
         }
      },
      "addServer": {
         "titlePageAdd": "ADICIONE AGORA",
         "subTitlePageAdd": "Instalação do bot, clique no botão abaixo 'ADICIONAR BOT!', para instalar em seu servidor",
         "btnTitle": "ADICIONAR BOT!",
         "attentionNote": {
            "title": "Atenção!",
            "subTitle": "Clique no botão acima de 'Adicionar Bot' para instalar."
         },
         "denyNote": {
            "btnNote": "VOLTAR",
            "title": "Atenção!",
            "subTitle": "Você precisa ser dono ou administrador deste servidor"
         }
      },
      "navBar": {
         "deckServer": "Servidor do Deck",
         "support": "Suporte",
         "welcome": "Bem-vindo,",
         "exit": "Sair",
         "servers": "Meus servidores",
         "subscription": "Assinatura",
         "searchServers": "Buscar servidores",
         "withoutPermission": "Sem permissao",
         "serverEdit": "Gerenciar Servidor",
         "dash": "Página inicial",
         "welcomeGood": "Boas-vindas & Adeus",
         "commands": "Comandos Gerais",
         "custom": "Comandos Personalizados",
         "giveaway": "Sorteio",
         "poll": "Enquetes",
         "ecommerce": {
            "settings": "Configurações",
            "products": "Produtos",
            "sales": "Vendas",
            "monitoring": "Monitoramento"
         },
         "ticket": {
            "settings": "Configurações",
            "search": "Pesquisar"
         },
         "whitelist": {
            "settings": "Configurações",
            "questions": "Perguntas",
            "complete": "Formulários Completados"
         },
         "serverSettings": "Personalizar servidor"
      },
      "previewMessage": {
         "preview": {
            "title": "Pré-visualização",
            "label": "Sua mensagem aqui"
         },
         "message": "Mensagem",
         "custom": {
            "title": "Personalizar Mensagem",
            "label": "Sua mensagem aqui"
         },
         "image": {
            "title": "Mensagem por imagem",
            "menu": {
               "custom": {
                  "titleMenu": "Personalizar",
                  "titleText": "Título",
                  "subtitleText": "Subtítulo",
                  "textColor": "Cor do Texto",
                  "select": "Selecionar Fonte",
                  "border": "Tamanho da Borda",
                  "font": "Tamanho da Fonte"
               },
               "select": "Selecionar Imagem",
               "upload": "Enviar Imagem"
            }
         },
         "buttons": {
            "image": "Imagem",
            "embed": "Embed"
         },
         "uploadImage": {
            "title": "Enviar Imagem",
            "up": "Enviar",
            "buttons": {
               "save": "Salvar",
               "cancel": "Cancelar"
            }
         },
         "embedMessage": {
            "title": "Mensagem Incorporada (Embed)",
            "color": "Cor da Faixa",
            "author": "Autor",
            "titleEmbed": "Título",
            "message": "Mensagem",
            "fields": {
               "title": "Campos",
               "btn": "Adicionar campo",
               "field": {
                  "title": "Campo",
                  "subTitle": "Título do Campo",
                  "value": "Valor do Campo"
               },
               "remove": "Remover campo"
            },
            "footer": "Rodapé",
            "image": "Imagem da Mensagem"
         }
      },
      "congratsPage": {
         "title": "Obrigado por instalar nosso bot do Discord",
         "texts": [
            "Olá! Gostaríamos de expressar nossa sincera gratidão por instalar nosso bot do Discord em seu servidor. Sua confiança em nossa tecnologia e equipe é extremamente valiosa para nós.",
            "Com nosso bot do Discord, esperamos proporcionar uma experiência única e agradável para seus usuários. Nosso objetivo é facilitar a comunicação e o compartilhamento de informações em seu servidor, ajudando você a construir uma comunidade forte e engajada.",
            "Constantemente buscamos melhorar nosso bot e trazer novos recursos para tornar sua experiência ainda melhor. Estamos animados para o futuro e mal podemos esperar para compartilhar as atualizações que estamos preparando.",
            "Mais uma vez, muito obrigado por escolher nosso bot do Discord. Esperamos continuar fornecendo soluções tecnológicas de alta qualidade e ajudando sua comunidade a crescer e prosperar."
         ],
         "button": "Ir!"
      },
      "donateConfig": {
         "configPage": {
            "server": "Informações do Servidor",
            "serverBenefits": "Essas informações aparecerão na guia de classificação em nosso site.",
            "description": "Descrição",
            "tags": "Tags",
            "url": "URL de Convite",
            "generate": "Gerar",
            "urlBanner": "URL do Banner",
            "save": "Salvar"
         }
      }
   }
}

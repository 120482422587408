<template>
   <div class="auth-main">
      <div class="logo_deck">
         <img src="@/assets/images/logos/logo_s.png" width="25" class="d-inline-block align-top mr-2" alt="">DECK
      </div>
      
      <div class="auth_div vivify fadeIn">
         <div class="auth_brand">
            <a class="navbar-brand" href="javascript:;">
               <img src="@/assets/images/logos/logo_v_t.png" class="w-75 mb-5"> 
            </a>                                                
         </div>
         <div class="card" style="border: 1px solid #FF7321 !important;">
            <div class="body">
               <p class="lead">{{ $t("serverSettings.login.title") }}</p>
               <button type="button" class="btn btn-lg btn-block glow-on-hover mx-auto mt-3" @click="efetuarLogin">
                  <img src="@/assets/images/logos/discord.png" width="35" class="mr-3">{{ $t("serverSettings.login.discord") }}
               </button>
               <div class="footer">
                  <p class="mt-3 mb-2"><small>by</small> One Way</p>
               </div>
            </div>
         </div>
      </div>
      <div class="animate_lines">
         <div class="line"></div>
         <div class="line"></div>
         <div class="line"></div>
         <div class="line"></div>
         <div class="line"></div>
         <div class="line"></div>
      </div>
    </div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'Login',
   computed: {
		...mapState({
			clientId: state => state.clientId,
			redirectUri: state => state.redirectUri
		})
	},
   methods: {
		efetuarLogin : function () {
         window.location.href = "https://discord.com/api/oauth2/authorize?client_id="+ this.clientId +"&redirect_uri="+ this.redirectUri +"&response_type=token&scope=guilds%20identify%20email+guilds.members.read";
		}
	}
}

</script>

<style scoped>

.glow-on-hover {
   width: max-content;
   height: 80px !important;
   font-size: 18px !important;
   outline: none;
   color: #fff !important;
   background: #222427;
   border: 1px solid #1f2022 !important;
   cursor: pointer;
   position: relative;
   z-index: 0;
   border-radius: 5px;
   padding: 10px 24px;
}

.glow-on-hover:before {
   content: '';
   background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
   position: absolute;
   top: -2px;
   left:-2px;
   background-size: 400%;
   z-index: -1;
   filter: blur(5px);
   width: calc(100% + 4px);
   height: calc(100% + 4px);
   animation: glowing 20s linear infinite;
   opacity: 0;
   transition: opacity .3s ease-in-out;
   border-radius: 5px;
}

.glow-on-hover:active {
   color: #222427
}

.glow-on-hover:active:after {
   background: transparent;
}

.glow-on-hover:hover:before {
   opacity: 1;
}

.glow-on-hover:after {
   z-index: -1;
   content: '';
   position: absolute;
   width: 100%;
   height: 100%;
   background: #222427;
   left: 0;
   top: 0;
   border-radius: 5px;
}

@keyframes glowing {
   0% { background-position: 0 0; }
   50% { background-position: 400% 0; }
   100% { background-position: 0 0; }
}

.logo_deck {
   position: fixed;
   bottom: 25px;
   right: 25px;
}

</style>
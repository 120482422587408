<template>
   <nav class="navbar navbar-fixed-top nav_sobre">
      <div class="container-fluid">
         <div class="navbar-left">
            <div class="navbar-btn">
               <a href="javascript:;"><img src="@/assets/images/logos/logo_s.png" alt="Deck Logo" class="img-fluid logo"></a>
               <button type="button" class="btn-toggle-offcanvas" @click="toggleOffcanvas"><i class="far fa-bars"></i></button>
            </div>
            <form id="navbar-search" class="navbar-form search-form weight-600 font-16">
               <i class="fa fa-home color-theme mr-2"></i><i class="fa fa-angle-right mr-2"></i>
               <span class="color-theme">{{ $route.name != null ? $route.name.replace(/_/g, " ").replace(/-/g, "/") : '' }}</span>
            </form>
         </div>
         <div class="navbar-right">
            <div id="navbar-menu">
               <ul class="nav navbar-nav">
                  <!-- <li><router-link to="/Premium"><a href="javascript:;" class="icon-menu"><i title="Premium" class="far fa-usd-circle colorIcon"></i></a></router-link></li> -->
                  <li class="dropdown language-menu hidden-xs">
                     <a href="javascript:void(0);" class="dropdown-toggle icon-menu" data-toggle="dropdown"><i class="far fa-globe-americas"></i></a>
                     <div class="dropdown-menu language_widget mt-0">
                        <a class="dropdown-item pt-2 pb-2" href="javascript:void(0);" @click="changeLang('en-US')"><img src="@/assets/images/lang/en-US.png"> English</a>
                        <a class="dropdown-item pt-2 pb-2" href="javascript:void(0);" @click="changeLang('pt-BR')"><img src="@/assets/images/lang/pt-BR.png"> Português</a>
                     </div>
                  </li>
                  <li class="hidden-xs"><a href="javascript:void(0);" id="btnFullscreen" class="icon-menu" @click="toggleFullScreen"><i class="far fa-arrows-alt"></i></a></li>
                  <li><a href="javascript:;" class="icon-menu" @click="deslogar"><i class="far fa-sign-out"></i></a></li>
               </ul>
            </div>
         </div>
      </div>
   </nav>
</template>

<script>

import $ from 'jquery'
import { mapState } from 'vuex'

export default {
	name: 'MenuNavBar',
   computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario,
         tokenSessao: state => state.tokenSessao,
         urlRest: state => state.urlRest
      })
   },
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      toggleOffcanvas : function () {
         $("body").toggleClass("offcanvas-active");
      },
      toggleFullScreen : function () {
         var e;
         (e = e || document.documentElement),
            document.fullscreenElement ||
            document.mozFullScreenElement ||
            document.webkitFullscreenElement ||
            document.msFullscreenElement
               ? document.exitFullscreen
               ? document.exitFullscreen()
               : document.msExitFullscreen
               ? document.msExitFullscreen()
               : document.mozCancelFullScreen
               ? document.mozCancelFullScreen()
               : document.webkitExitFullscreen && document.webkitExitFullscreen()
            : e.requestFullscreen
            ? e.requestFullscreen()
            : e.msRequestFullscreen
            ? e.msRequestFullscreen()
            : e.mozRequestFullScreen
            ? e.mozRequestFullScreen()
            : e.webkitRequestFullscreen &&
               e.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
      },
		deslogar : function () {
			this.$store.dispatch('deslogar')
      },
      changeLang : function (lang) {
         localStorage.lang = lang;
         this.$i18n.locale = lang;
      }
   }
}

</script>

<style>
.nav_sobre {
   z-index: 0;
}

.btnPagNav {
   display: inline-block;
   padding: 8px 10px;
   font-size: 16px;
   font-weight: 500;
   border: 1px dashed #FF7321;
   cursor: pointer;
   position: relative;
   background-color: transparent;
   overflow: hidden;
   z-index: 1;
   border-radius: 5px;
}

.btnPagNav::before {
   content: "";
   position: absolute;
   left: 0;
   top: 0;
   width: 100%;
   height: 100%;
   background-color: #FF7321;
   transform: translateX(-100%);
   transition: all .3s;
   z-index: -1;
}

.btnPagNav:hover::before {
   transform: translateX(0);
}

.colorIcon:hover {
   color: yellow;
}
</style>
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import jQuery from 'jquery'
import vSelect from "vue-select"
import datePicker from 'vuejs-datepicker'
import Axios from 'axios'
import Swal from 'sweetalert2'
import VueTheMask from 'vue-the-mask'
import "vue-select/dist/vue-select.css"
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import Verte from 'verte';
import VueI18n from 'vue-i18n'
import translate from './lang'
global.jQuery = jQuery
global.$ = jQuery

Vue.prototype.$axios = Axios
Vue.prototype.$toast = Swal.mixin({
   toast: true,
   position: 'top-end',
   showConfirmButton: false,
   timer: 5000,
   timerProgressBar: true,
   didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
   }
})

Vue.directive('focus', {
   inserted: function (el) {
      if (window.innerWidth > 768) {
         el.focus()
      }
   }
})

require("@/assets/vendor/bootstrap/css/bootstrap.min.css")
require("@/assets/vendor/font-awesome/css/font-awesome.css")
// require("@/assets/vendor/animate-css/vivify.min.css")
// require("@/assets/vendor/dropify/css/dropify.min.css")
// require("@/assets/vendor/chartist/css/chartist.min.css")
// require("@/assets/vendor/chartist-plugin-tooltip/chartist-plugin-tooltip.css")
// require("@/assets/vendor/c3/c3.min.css")
// require("@/assets/vendor/toastr/toastr.min.css")
// require("@/assets/vendor/jvectormap/jquery-jvectormap-2.0.3.min.css")
require("@/assets/vendor/verte/verte.css")
require("@/assets/vendor/jodit/dark.css")
require("@/assets/css/mooli.min.css")
require("@/assets/bundles/vendorscripts.bundle.js")
// require("@/assets/bundles/mainscripts.bundle.js")

Vue.use(VueTheMask)
Vue.use(VueI18n)

const i18n = new VueI18n({
   locale: localStorage.lang == null ? 'en-US' : localStorage.lang,
   messages: translate
})

Vue.component(Cropper)
Vue.component("v-select", vSelect)
Vue.component("datePicker", datePicker)
Vue.component('verte', Verte);

Vue.config.productionTip = false

new Vue({
   i18n,
   router,
   store,
   render: h => h(App)
}).$mount('#app')
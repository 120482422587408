<template>
   <div id="left-sidebar" class="sidebar">
      <a href="javascript:void(0);" class="menu_toggle" @click="toggleMenu"><i class="fa fa-angle-left"></i></a>
      <div class="navbar-brand">
         <router-link to="/"><img src="@/assets/images/logos/logo_s.png" alt="DECK Logo" class="img-fluid logo"><span class="text_logo">DECK</span></router-link>
         <button type="button" class="btn-toggle-offcanvas btn btn-sm float-right" @click="toggleOffcanvas"><i class="far fa-times"></i></button>
      </div>
      <div class="sidebar-scroll">
         <div class="user-account mb-3">
            <div class="user_div">
               <img :src="dadosUsuario.usuarioFoto == null ? '' : dadosUsuario.usuarioFoto" @error="imageError" class="user-photo" alt="User Picture">
            </div>
            <div class="dropdown">
               <span>{{ $t("navBar.welcome") }}</span>
               <a href="javascript:void(0);" class="dropdown-toggle user-name" data-toggle="dropdown">
                  <strong>{{ String(dadosUsuario.username).length > 17 ? String(dadosUsuario.username).substring(0, 17) +'...' : dadosUsuario.username }}</strong>
               </a>
               <ul class="dropdown-menu dropdown-menu-right account vivify flipInY">
                  <li><a href="javascript:;" class="p-1" @click="deslogar"><i class="far fa-sign-out font-13"></i>{{ $t("navBar.exit") }}</a></li>
               </ul>
            </div>
         </div>

         <div class="borde_bottom pb-1 painel_server_menu">
            <nav class="sidebar-nav">
               <hr class="my-2">
               <ul class="metismenu animation-li-delay">
                  <li :class="$route.path == '/' ? 'active' : ''" @click="limparBarra">
                     <router-link to="/"><i class="fal fa-server"></i> <span>{{ $t("navBar.servers") }}</span></router-link>
                  </li>
                  <li :class="$route.path == '/servers' ? 'active' : ''" @click="limparBarra" v-if="dadosUsuario.userAdmin">
                     <router-link to="/servers"><i class="fal fa-search"></i> <span>{{ $t("navBar.searchServers") }}</span></router-link>
                  </li>
               </ul>
               <hr class="my-2">
            </nav>
         </div>

         <div class="user-account d-flex my-2" v-if="serverS.id != null">
            <div class="align-self-center user_div pb-0">
               <img :src="serverS.url_icon == null ? '' : serverS.url_icon" class="rounded user-photo" @error="imageError" />
            </div>
            <div class="align-self-center">
               <strong class="limitador ml-3">{{ String(serverS.name).length > 17 ? String(serverS.name).substring(0, 17) +'...' : String(serverS.name) }}</strong>
               <span class="limitador ml-3 font-12" v-if="serverS.donate">
                  <span><i class="fal fa-star font-10 color-theme mr-1"></i> {{ serverS.donatePack ? serverS.donatePack : '-' }}</span>
               </span>
               <span class="limitador ml-3 font-12" v-if="serverS.donate && (dadosUsuario.id == serverS.id_dono || serverS.isAdmin)">
                  <i class="fal fa-calendar font-10 color-theme mr-1"></i>
                  <span> {{ $t("premium.booster.cancelAfterExpires") }} {{ new Date(serverS.donateExpiration.year +'/'+ serverS.donateExpiration.monthValue +'/'+ serverS.donateExpiration.dayOfMonth).toLocaleDateString() }}</span>
               </span>
            </div>
         </div>

         <nav id="left-sidebar-nav" class="sidebar-nav" v-show="serverS.id != null">
            <ul id="main-menu" class="metismenu animation-li-delay">
               <li class="mt-2" :class="$route.path == '/Premium' ? 'active' : ''">
                  <router-link to="/Premium" v-if="dadosUsuario.id == serverS.id_dono"><i class="fal fa-star"></i> <span>{{ $t("navBar.subscription") }}</span></router-link>
                  <a href="javascript:;" class="text-secondary" v-else>{{ $t("navBar.subscription") }} <i class="far fa-lock font-13"></i></a>
               </li>

               <li class="header text-secondary mt-2">{{ $t("navBar.serverEdit") }}</li>
               <li :class="$route.path == '/Dashboard' ? 'active' : ''">
                  <router-link to="/Dashboard"><i class="fal fa-home"></i> <span>{{ $t("navBar.dash") }}</span></router-link>
               </li>
               <!-- <li :class="$route.path == '/donateConfig' ? 'active' : ''">
                  <router-link to="/donateConfig" v-if="dadosUsuario.id == serverS.id_dono || serverS.isAdmin"><i class="fal fa-cog"></i> <span>{{ $t("navBar.serverSettings") }}</span></router-link>
                  <a href="javascript:;" class="text-secondary" v-else>{{ $t("navBar.serverSettings") }} <i class="far fa-lock font-13"></i></a>
               </li> -->
               <li :class="$route.path == '/Welcome_&_Goodbye' ? 'active' : ''">
                  <router-link to="/Welcome_&_Goodbye" v-if="dadosUsuario.id == serverS.id_dono || serverS.isAdmin"><i class="fal fa-comments-alt"></i> <span>{{ $t("navBar.welcomeGood") }}</span></router-link>
                  <a href="javascript:;" class="text-secondary" v-else>{{ $t("navBar.welcomeGood") }} <i class="far fa-lock font-13"></i></a>
               </li>
               <li :class="$route.path == '/GeneralCommands' ? 'active' : ''">
                  <router-link to="/GeneralCommands" v-if="dadosUsuario.id == serverS.id_dono || serverS.isAdmin"><i class="fal fa-list-ul"></i> <span>{{ $t("navBar.commands") }}</span></router-link>
                  <a href="javascript:;" class="text-secondary" v-else>{{ $t("navBar.commands") }} <i class="far fa-lock font-13"></i></a>
               </li>
               <li :class="$route.path == '/ComandosCustom' ? 'active' : ''">
                  <router-link to="/premiumRequired/Basic" v-if="serverS.donatePack == 'Free'"><i class="fal fa-code"></i> <span>{{ $t("navBar.custom") }}</span></router-link>
                  <router-link to="/ComandosCustom" v-else-if="dadosUsuario.id == serverS.id_dono || serverS.isAdmin"><i class="fal fa-code"></i> <span>{{ $t("navBar.custom") }}</span></router-link>
                  <a href="javascript:;" class="text-secondary" v-else>{{ $t("navBar.custom") }} <i class="far fa-lock font-13"></i></a>
               </li>
               <li :class="$route.path == '/Giveaway' ? 'active' : ''">
                  <router-link to="/Giveaway" v-if="dadosUsuario.id == serverS.id_dono || serverS.isAdmin"><i class="fal fa-gift"></i> <span>{{ $t("navBar.giveaway") }}</span></router-link>
                  <a href="javascript:;" class="text-secondary" v-else>{{ $t("navBar.giveaway") }} <i class="far fa-lock font-13"></i></a>
               </li>
               <li :class="$route.path == '/Polls' ? 'active' : ''">
                  <router-link to="/Polls" v-if="dadosUsuario.id == serverS.id_dono || serverS.isAdmin"><i class="fal fa-poll"></i> <span>{{ $t("navBar.poll") }}</span></router-link>
                  <a href="javascript:;" class="text-secondary" v-else>{{ $t("navBar.poll") }} <i class="far fa-lock font-13"></i></a>
               </li>
               <li :class="$route.path == '/ticketSettings' || $route.path == '/ticketSearch' ? 'active' : ''">
                  <a href="#Ticket" class="has-arrow"><i class="fal fa-receipt"></i><span>Ticket</span></a>
                  <ul>
                     <li :class="$route.path == '/ticketSettings' ? 'active' : ''">
                        <router-link to="/premiumRequired/Basic" v-if="serverS.donatePack == 'Free'">{{ $t("navBar.ticket.settings") }}</router-link>
                        <router-link to="/ticketSettings" v-else-if="dadosUsuario.id == serverS.id_dono || serverS.isAdmin">{{ $t("navBar.ticket.settings") }}</router-link>
                        <a href="javascript:;" class="text-secondary" v-else>{{ $t("navBar.ticket.settings") }} <i class="far fa-lock font-13"></i></a>
                     </li>
                     <li :class="$route.path == '/ticketSearch' ? 'active' : ''">
                        <router-link to="/premiumRequired/Basic" v-if="serverS.donatePack == 'Free'">{{ $t("navBar.ticket.search") }}</router-link>
                        <router-link to="/ticketSearch" v-else-if="dadosUsuario.id == serverS.id_dono || serverS.isAdmin || serverS.roles.some(r => r.id == serverS.idPermissionTicket)">{{ $t("navBar.ticket.search") }}</router-link>
                        <a href="javascript:;" class="text-secondary" v-else>{{ $t("navBar.ticket.search") }} <i class="far fa-lock font-13"></i></a>
                     </li>
                  </ul>
               </li>

               <li :class="$route.path == '/ecommerceSettings' || $route.path == '/ecommerceTermsPurchase' || $route.path == '/ecommerceProducts' || $route.path == '/ecommerceSales' || $route.path == '/ecommerceOrders' || $route.path == '/ecommerceMonitoring' ? 'active' : ''">
                  <a href="#Ecommerce" class="has-arrow"><i class="fal fa-shopping-cart"></i><span>Ecommerce</span></a>
                  <ul>
                     <li :class="$route.path == '/ecommerceSettings' ? 'active' : ''">
                        <router-link to="/premiumRequired/Standard" v-if="serverS.donatePack == 'Free' || serverS.donatePack == 'Basic'">{{ $t("navBar.ecommerce.settings") }}</router-link>
                        <router-link to="/ecommerceSettings" v-else-if="dadosUsuario.id == serverS.id_dono || serverS.isAdmin">{{ $t("navBar.ecommerce.settings") }}</router-link>
                        <a href="javascript:;" class="text-secondary" v-else>{{ $t("navBar.ecommerce.settings") }} <i class="far fa-lock font-13"></i></a>
                     </li>
                     <li :class="$route.path == '/ecommerceProducts' ? 'active' : ''">
                        <router-link to="/premiumRequired/Standard" v-if="serverS.donatePack == 'Free' || serverS.donatePack == 'Basic'">{{ $t("navBar.ecommerce.products") }}</router-link>
                        <router-link to="/ecommerceProducts" v-else-if="dadosUsuario.id == serverS.id_dono || serverS.isAdmin || serverS.roles.some(r => r.id == serverS.idPermissionEcommerce)">{{ $t("navBar.ecommerce.products") }}</router-link>
                        <a href="javascript:;" class="text-secondary" v-else>{{ $t("navBar.ecommerce.products") }} <i class="far fa-lock font-13"></i></a>
                     </li>
                     <li :class="$route.path == '/ecommerceSales' ? 'active' : ''">
                        <router-link to="/premiumRequired/Standard" v-if="serverS.donatePack == 'Free' || serverS.donatePack == 'Basic'">{{ $t("navBar.ecommerce.sales") }}</router-link>
                        <router-link to="/ecommerceSales" v-else-if="dadosUsuario.id == serverS.id_dono || serverS.isAdmin || serverS.roles.some(r => r.id == serverS.idPermissionEcommerce)">{{ $t("navBar.ecommerce.sales") }}</router-link>
                        <a href="javascript:;" class="text-secondary" v-else>{{ $t("navBar.ecommerce.sales") }} <i class="far fa-lock font-13"></i></a>
                     </li>
                     <li :class="$route.path == '/ecommerceMonitoring' ? 'active' : ''">
                        <router-link to="/premiumRequired/Standard" v-if="serverS.donatePack == 'Free' || serverS.donatePack == 'Basic'">{{ $t("navBar.ecommerce.monitoring") }}</router-link>
                        <router-link to="/ecommerceMonitoring" v-else-if="dadosUsuario.id == serverS.id_dono || serverS.isAdmin || serverS.roles.some(r => r.id == serverS.idPermissionEcommerce)">{{ $t("navBar.ecommerce.monitoring") }}</router-link>
                        <a href="javascript:;" class="text-secondary" v-else>{{ $t("navBar.ecommerce.monitoring") }} <i class="far fa-lock font-13"></i></a>
                     </li>
                  </ul>
               </li>

               <li class="header text-secondary mt-3" v-if="dadosUsuario.id == serverS.id_dono || serverS.isAdmin || serverS.roles.some(r => r.id == serverS.idPermissionWl)">FiveM</li>
               <li :class="$route.path == '/whitelistQuestions' || $route.path == '/whitelistSettings' || $route.path == '/whitelistCompletedForms' ? 'active' : ''">
                  <a href="#Whitelist" class="has-arrow"><i class="fal fa-clipboard"></i><span>Whitelist</span></a>
                  <ul>
                     <li :class="$route.path == '/whitelistSettings' ? 'active' : ''">
                        <router-link to="/premiumRequired/Basic" v-if="serverS.donatePack == 'Free'">{{ $t("navBar.whitelist.settings") }}</router-link>
                        <router-link to="/whitelistSettings" v-else-if="dadosUsuario.id == serverS.id_dono || serverS.isAdmin">{{ $t("navBar.whitelist.settings") }}</router-link>
                        <a href="javascript:;" class="text-secondary" v-else>{{ $t("navBar.whitelist.settings") }} <i class="far fa-lock font-13"></i></a>
                     </li>
                     <li :class="$route.path == '/whitelistQuestions' ? 'active' : ''">
                        <router-link to="/premiumRequired/Basic" v-if="serverS.donatePack == 'Free'">{{ $t("navBar.whitelist.questions") }}</router-link>
                        <router-link to="/whitelistQuestions" v-else-if="dadosUsuario.id == serverS.id_dono || serverS.isAdmin || serverS.roles.some(r => r.id == serverS.idPermissionWl)">{{ $t("navBar.whitelist.questions") }}</router-link>
                        <a href="javascript:;" class="text-secondary" v-else>{{ $t("navBar.whitelist.questions") }} <i class="far fa-lock font-13"></i></a>
                     </li>
                     <li class="mb-4" :class="$route.path == '/whitelistCompletedForms' ? 'active' : ''">
                        <router-link to="/premiumRequired/Basic" v-if="serverS.donatePack == 'Free'">{{ $t("navBar.whitelist.complete") }}</router-link>
                        <router-link to="/whitelistCompletedForms" v-else-if="dadosUsuario.id == serverS.id_dono || serverS.isAdmin || serverS.roles.some(r => r.id == serverS.idPermissionWl)">{{ $t("navBar.whitelist.complete") }}</router-link>
                        <a href="javascript:;" class="text-secondary" v-else>{{ $t("navBar.whitelist.complete") }} <i class="far fa-lock font-13"></i></a>
                     </li>
                  </ul>
               </li>
            </ul>
         </nav>

         <div class="borde_bottom pb-1 painel_server_menu">
            <nav class="sidebar-nav">
               <ul class="metismenu animation-li-delay">
                  <li class="header text-secondary mt-3">{{ $t("navBar.support") }}</li>
                  <li>
                     <a href="https://discord.gg/sVsuS8QQfd" target="_blank"><i class="fab fa-discord"></i> <span>{{ $t("navBar.deckServer") }}</span></a>
                  </li>
               </ul>
            </nav>
         </div>
      </div>
   </div>
</template>

<script>

import Vue from 'vue'
import $ from 'jquery'
import { mapState } from 'vuex'

export default {
	name: 'MenuLateral',
   computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario,
         serverS: state => state.serverS
      })
   },
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      toggleMenu : function () {
         $("body").toggleClass("toggle_menu_active");
      },
      toggleOffcanvas : function () {
         $("body").toggleClass("offcanvas-active");
      },
      deslogar : function () {
			this.$store.dispatch('deslogar')
      },
      abrirEditarSenha : function () {
         this.$emit('abrirEditarSenha')
      },
      abrirEditarFoto : function () {
         this.$emit('abrirEditarFoto')
      },
      limparBarra : function () {
         this.serverS.id = null
      }
   },
   mounted() {
      Vue.nextTick(function() {
         $("#main-menu").metisMenu()
         $(".sidebar").removeClass("light_active")
      }.bind(this));
   }
}

</script>
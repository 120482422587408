<template>
	<div id="body">
		<!-- Page Loader -->
		<div class="page-loader-wrapper" v-if="isCarregando == true">
			<div class="loader">
				<img src="@/assets/images/dashboard/guarda.png" class="mb-4" width="200" alt="DECK">
				<h1 class="mt-3">Loading...</h1>        
			</div>
		</div>

		<!-- Overlay For Sidebars -->
		<div class="overlay" @click="toggleOverlayOpen"></div>

		<div id="wrapper" v-if="isLogado">
			<!-- Page top navbar -->
			<NavBar />
        
			<!-- Main left sidebar menu -->
			<Lateral @abrirEditarSenha="abrirEditarSenha" @abrirEditarFoto="abrirEditarFoto" />
        
			<!-- Main body part  -->
			<div id="main-content" @click="removeClassContent">
				<div class="container-fluid">
					<router-view class="pt-4" />
				</div>
			</div>
		</div>

		<Login v-if="!isLogado" />
	</div>
</template>

<script>

import Vue from 'vue'
import $ from 'jquery'
import { mapState } from 'vuex'
import Login from '@/views/Login.vue'
import NavBar from '@/components/MenuNavBar.vue'
import Lateral from '@/components/MenuLateral.vue'

export default {
	name: 'App',
	data: function() {
		return {
         imageTemporary: null,
         image: null,
         modalSenha: {'senha': '', 'novaSenha': ''}
		}
	},
	computed: {
		... mapState({
			dadosUsuario: state => state.dadosUsuario,
			isCarregando: state => state.isCarregando,
			isLogado: state => state.isLogado,
			urlRest: state => state.urlRest
		})
	},
	watch: {
		isLogado : function(value) {
			if (value) {
				require("@/assets/vendor/bootstrap/js/bootstrap.bundle.js")
			}
		}
	},
	components: {
		Lateral, NavBar, Login
	},
	methods: {
		toggleOverlayOpen : function () {
			$(".overlay").toggleClass("open");
		},
		removeClassContent : function () {
			$("body").removeClass("offcanvas-active")
			$(".sticky-note").removeClass("open");
		},
		change : function ({ coordinates, canvas }) {
         coordinates;
			this.$refs.images.value = null;
         this.image = canvas.toDataURL('image/jpeg');
		},
      onFileChange(e) {
         var files = e.target.files || e.dataTransfer.files;
         if (!files.length)
         return;
         this.createImage(files[0]);
      },
      createImage(file) {
         var reader = new FileReader();
         var vm = this;

         reader.onload = (e) => {
            vm.imageTemporary = e.target.result;
         };
         reader.readAsDataURL(file);
      },
      abrirEditarSenha : function () {
         this.modalSenha = {'senha': '', 'novaSenha': ''}

         $('#modalEditarSenha').modal('show')
      },
      abrirEditarFoto : function () {
         this.imageTemporary = null
         this.image = null

         $('#modalEditarFoto').modal('show')
      },
      salvarFoto : function () {
         if (this.image == null || String(this.image).length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'Foto inválida!'
            })

            return
         }

			this.$store.dispatch('alternarTelaCarregamento', true)

         this.$axios({
            method: 'post',
            url: this.urlRest +'usuarios/changephoto',
            headers: {
					'Content-Type': 'application/json'
            },
				data: JSON.stringify({
               idUsuario: this.dadosUsuario.id,
               foto: this.image
            })
         }).then(() => {
				$('#modalEditarFoto').modal('hide')
            this.dadosUsuario.usuarioFoto = this.image

            this.$toast.fire({
               icon: 'success',
               title: 'Foto alterada!'
            })
         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  })
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               })
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         })
      }
	},
	mounted(){
		this.$store.dispatch('verificarCredenciais')

		Vue.nextTick(function() {
         document.documentElement.setAttribute("data-theme", "dark")
         localStorage.setItem("theme", "dark")
      }.bind(this));
	}
}

</script>
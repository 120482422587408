import Vue from 'vue'
import $ from 'jquery'
import store from '@/store'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
   {
      path: '/',
      name: 'My_Servers',
      component: () => import('../views/PaginaInicial.vue')
   },
   {
      path: '/Servers',
      name: 'Servers',
      component: () => import('../views/Servidores.vue')
   },
   {
      path: '/LiberaServer:server',
      name: 'Add_deck_to_server',
      component: () => import('../views/LiberaServer.vue')
   },
   {
      path: '/Dashboard',
      name: 'Dashboard',
      component: () => import('../views/pages/Dashboard.vue')
   },
   {
      path: '/Welcome_&_Goodbye',
      name: 'Welcome_&_Goodbye',
      component: () => import('../views/pages/Welcome_&_Goodbye.vue')
   },
   {
      path: '/whitelistCompletedForms',
      name: 'Whitelist_completed_forms',
      component: () => import('../views/pages/WhitelistCompletedForms.vue')
   },
   {
      path: '/whitelistQuestions',
      name: 'Whitelist_questions',
      component: () => import('../views/pages/WhitelistQuestions.vue')
   },
   {
      path: '/whitelistSettings',
      name: 'Whitelist_settings',
      component: () => import('../views/pages/WhitelistSettings.vue')
   },
   {
      path: '/ticketSettings',
      name: 'Ticket_settings',
      component: () => import('../views/pages/TicketSettings.vue')
   },
   {
      path: '/ticketSearch',
      name: 'Ticket_search',
      component: () => import('../views/pages/TicketSearch.vue')
   },
   {
      path: '/GeneralCommands',
      name: 'General_Commands',
      component: () => import('../views/pages/ComandosGerais.vue')
   },
   {
      path: '/ComandosCustom',
      name: 'Custom_Commands',
      component: () => import('../views/pages/ComandosCustom.vue')
   },
   {
      path: '/Giveaway',
      name: 'Giveaway',
      component: () => import('../views/pages/Giveaway.vue')
   },
   {
      path: '/Premium',
      name: 'Premium',
      component: () => import('../views/pages/Premium.vue')
   },
   {
      path: '/Polls',
      name: 'Polls',
      component: () => import('../views/pages/Polls.vue')
   },
   {
      path: '/donateConfig',
      name: 'Server_Settings',
      component: () => import('../views/pages/DonateConfig.vue')
   },
   {
      path: '/ecommerceSettings',
      name: 'Ecommerce_Settings',
      component: () => import('../views/pages/EcommerceSettings.vue')
   },
   {
      path: '/ecommerceProducts',
      name: 'Ecommerce_Products',
      component: () => import('../views/pages/EcommerceProducts.vue')
   },
   {
      path: '/ecommerceSales',
      name: 'Ecommerce_Sales',
      component: () => import('../views/pages/EcommerceSales.vue')
   },
   {
      path: '/ecommerceMonitoring',
      name: 'Ecommerce_Monitoring',
      component: () => import('../views/pages/EcommerceMonitoring.vue')
   },
   {
      path: '/premiumRequired/:pack',
      name: 'Premium_Required',
      component: () => import('../views/pages/PremiumRequired.vue')
   }
]

const router = new VueRouter({
   mode: 'history',
   base: process.env.BASE_URL,
   routes
})

router.beforeEach((to, from, next) => {
   $("body").removeClass("offcanvas-active");

   if (!store.state.isLogado && to.path != '/') {
      router.push('/')
   } else {
      next()
   }
})

export default router
